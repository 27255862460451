<template>
  <HpAnchor
    :href="`tel:${phoneNumber}`"
    class="block whitespace-nowrap text-center text-gray-900 no-underline"
    :class="{ 'text-white': monochrome }"
  >
    <span v-if="!hidden" class="block text-lg leading-snug">
      <span class="mr-1 font-semibold text-primary" :class="{ 'text-white': monochrome }">
        {{
          phoneNumber
            ? DisplayPhoneNumber(String(phoneNumber))
            : DisplayPhoneNumber(String(defaultNumber))
        }}
      </span>
      | TTY 711
    </span>
    <span
      v-if="isAep && !hidden"
      class="whitespace-nowrap text-xs text-gray-800 lg:text-sm"
      :class="{ 'text-white': monochrome }"
    >
      {{ hours }}
    </span>
    <span
      v-if="!isAep && !hidden"
      class="text-sm text-gray-800"
      :class="{ 'text-white': monochrome }"
    >
      {{ hours }}
    </span>
  </HpAnchor>
</template>

<script lang="ts" setup>
  import { SessionStore } from '~/stores/session'
  import { SiteStore } from '~/stores/site'
  import DisplaySupportHours from '~/composables/DisplaySupportHours'
  import { SupportNumbers } from '~/composables/SupportNumbers'

  const props = defineProps<{
    monochrome: {
      type: Boolean
    }
    isFooter: {
      type: Boolean
    }
  }>()

  const { getComponentContent } = Cms.use()
  const contactCardContent = await getComponentContent('HelperCardPageCompare')

  const { hours } = await DisplaySupportHours.use()

  const session = SessionStore.use()
  const site = SiteStore.use()
  const { path } = AppRoute.use()
  const { flag } = FeatureFlags.use()
  const isAep = computed(() => flag<boolean>('is-aep').value)

  const { phoneNumber, defaultNumber } = await SupportNumbers.use()

  const hidden = computed(() => {
    if (props.isFooter) {
      return false
    }
    return _isNil(phoneNumber.value)
  })
</script>

<style scoped></style>
