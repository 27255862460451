import type {
  ComponentContent,
  DisclosureContent,
  FaqContent,
  HelperCardContent,
  LogoContent,
  PageContent,
  StandbyTestimonial,
  StandbyTestimonialContent, SupportNumber,
  TestimonialForm,
  TooltipContent
} from '~/types/cms'
import { _replace } from '#build/imports'
import { SiteStore } from '~/stores/site'
import { contentKey } from '~/utils/cms'
import dayjs from 'dayjs'
import type { Ref } from 'vue'
import { toRefs } from '@vueuse/core'

const mapComponentContent = (x: any) => ({
  id: x.id,
  type: x.type.id,
  version: x.version,
  ext: Object.fromEntries(x.ext.map((y: any) => [y.key, y.value]))
})

const mapTooltipContent = (x: any) => ({
  id: x.id,
  type: x.type,
  version: x.version,
  title: x.title,
  body: x.body
})

const mapPageContent = (x: any) => ({
  ...mapComponentContent(x),
  headline: x.headline,
  headlineIcon: x.headline_icon,
  hintText: x.hint_text,
  nextText: x.next_text,
  nextIcon: x.next_icon,
  backText: x.back_text,
  metaTitle: x.meta_title
})

const mapFaqContent = (x: any): FaqContent => ({
  id: x.id,
  topic: x.topic,
  question: x.question,
  answer: x.answer,
  inclusionConditions: x.inclusion_conditions,
  exclusionConditions: x.exclusion_conditions
})

const mapSupportNumbers = (x: any): SupportNumber => ({
  number: x.number,
  data: x.data
})

const mapTestimonial = (x: any): StandbyTestimonial => ({
  name: x.name,
  quote: x.quote,
  stars: x.stars
})

const mapStandbyTestimonialContent = (x: any): StandbyTestimonialContent => ({
  id: x.id,
  header: x.Header,
  testimonials: x.testimonials.map(mapTestimonial),
  headerAlt1: x.header_alt_1
})

export class Cms {
  static use() {
    async function getPageContent(
      name: string,
      version?: string | null
    ): Promise<{ content: Ref<PageContent> }> {
      return getContent<PageContent>('px_page_component', name, version ?? null, mapPageContent)
    }

    async function getComponentContent(
      name: string,
      version?: string | null
    ): Promise<{ content: Ref<ComponentContent> }> {
      const site = SiteStore.use()

      const cached = site.componentContent[contentKey(name, version)]
      if (!!cached) return { content: ref(cached) }

      return getContent<ComponentContent>(
        'px_component',
        name,
        version ?? null,
        mapComponentContent
      )
    }

    async function getTestimonialForm(
      name: string
    ): Promise<{ testimonial: Ref<TestimonialForm> }> {
      const { getItems } = useDirectusItems()

      const { data } = await useAsyncData<any>(async () => {
        const items = await getItems<any>({
          collection: 'testimonial_forms',
          params: {
            fields: ['name', 'header', 'subheader', 'questions', 'footer'],
            filter: {
              name: name
            }
          }
        })

        return items.map<TestimonialForm>((x) => ({
          name: x.data,
          header: x.header,
          subheader: x.subheader,
          questions: x.questions,
          footer: x.footer
        }))
      })

      const [testimonial] = toRefs(data as Ref<TestimonialForm[]>)

      return {
        testimonial
      }
    }

    async function getTooltipContent(
      name: string,
      version?: string | null
    ): Promise<{ content: Ref<TooltipContent> }> {
      const site = SiteStore.use()

      const cached = site.tooltipContent[contentKey(name, version)]
      if (!!cached) return { content: ref(cached) }

      return getContent<TooltipContent>('px_tooltip', name, version ?? null, mapTooltipContent)
    }

    async function getStandbyTestimonialContent(
      name: string,
      version?: string | null
    ): Promise<{ content: Ref<StandbyTestimonialContent> }> {
      const site = SiteStore.use()

      const cached = site.standbyTestimonialContent[contentKey(name, version)]
      if (!!cached) return { content: ref(cached) }

      const content = getContent<StandbyTestimonialContent>(
        'px_standby_testimonials',
        name,
        version ?? null,
        mapStandbyTestimonialContent
      )

      return content
    }

    async function getContent<T>(
      collection: string,
      name: string,
      version: string | null,
      f: (item: any) => T
    ): Promise<{ content: Ref<T> }> {
      const { getItems } = useDirectusItems()

      const key = `${name}-content`

      // @ts-ignore
      const { data } = await useAsyncData<any>(key, async () => {
        const items = await getItems<any>({
          collection: collection,
          params: {
            fields: ['*.*'],
            filter: {
              _and: [
                {
                  type: name
                },
                {
                  _or: [
                    {
                      version: {
                        _null: true
                      }
                    },
                    {
                      version: {
                        _eq: version ?? 'xyz'
                      }
                    }
                  ]
                }
              ]
            }
          }
        })

        return items.map(f).find((x: any) => _isNil(version) || x.version === version)
      })

      return {
        content: data
      }
    }

    async function getComponentContentCollection(): Promise<{
      content: Ref<Array<ComponentContent>>
    }> {
      return getContentCollection('px_component', mapComponentContent)
    }

    async function getStandbyTestimonialContentCollection(): Promise<{
      content: Ref<Array<StandbyTestimonialContent>>
    }> {
      return getContentCollection('px_standby_testimonials', mapStandbyTestimonialContent)
    }

    async function getTooltipContentCollection(): Promise<{
      content: Ref<Array<TooltipContent>>
    }> {
      return getContentCollection('px_tooltip', mapTooltipContent)
    }

    async function getFaqContentCollection(): Promise<{
      content: Ref<Array<FaqContent>>
    }> {
      return getContentCollection('faqs', mapFaqContent)
    }

    async function getSupportNumberCollection(): Promise<{
        content: Ref<Array<SupportNumber>>
        }> {
          console.log('getSupportNumberCollection')
        return getContentCollection('px_support_numbers', mapSupportNumbers)
    }

    async function getContentCollection<T>(
      collection: string,
      f: (item: any) => T
    ): Promise<{ content: Ref<Array<T>> }> {
      const { getItems } = useDirectusItems()

      const key = `${collection}-collection`

      // @ts-ignore
      const { data } = await useAsyncData<any>(key, async () => {
        const items = await getItems<any>({
          collection: collection,
          params: {
            fields: ['*.*']
          }
        })

        return items.map(f)
      })

      return {
        content: data
      }
    }

    async function getCarrierLogoContent(): Promise<{
      logos: Ref<Array<LogoContent>>
    }> {
      const { getItems } = useDirectusItems()

      const { data } = await useAsyncData<any>(async () => {
        const items = await getItems<any>({
          collection: 'px_carrier_logo',
          params: {
            fields: ['key,plan_year,logo.filename_disk']
          }
        })

        return items.map((x) => ({
          key: x.key,
          planYear: x.plan_year,
          fileName: x.logo.filename_disk
        }))
      })

      return {
        logos: data
      }
    }

    async function getHelperCardContent(): Promise<{
      cards: Ref<Array<HelperCardContent>>
    }> {
      const { getItems } = useDirectusItems()

      const { data } = await useAsyncData<any>('helper-cards', async () => {
        const items = await getItems<any>({
          collection: 'px_helper_cards',
          params: {
            fields: ['helper_cards.*.*']
          }
        })

        return items
      })

      return {
        cards: data
      }
    }

    async function getCarrierDisclosureContent(
      planYear: number,
      keys: Array<string>
    ): Promise<{ disclosures: Ref<Array<DisclosureContent>> }> {
      // if (keys?.length < 1) return { disclosures: ref([]) }
      const { getItems } = useDirectusItems()

      const fetchKey = `disclosures-${keys.length < 1 ? 'all' : _join(keys, '_')}`

      const { data } = await useAsyncData<any>(fetchKey, async () => {
        const items = await getItems<any>({
          collection: 'px_carrier_disclosure',
          params: {
            fields: ['*'],
            filter: {
              _and: [
                {
                  plan_year: planYear
                },
                {
                  key:
                    keys.length < 1
                      ? undefined
                      : {
                          _in: keys
                        }
                }
              ]
            }
          }
        })

        return items.map((x) => ({
          key: x.key,
          brandName: x.brand_name,
          planYear: x.plan_year,
          body: x.body
        }))
      })

      return {
        disclosures: data
      }
    }

    function hydratePlaceholders(
      content: string,
      data: Record<string, any>,
      dateFormat = 'MM/DD/YYYY'
    ) {
      return Object.entries(data).reduce((agg, [key, val]) => {
        const dateTry = dayjs(val, ['MM/DD/YYYY', 'YYYY-MM-DD'], true)
        const exp = new RegExp(`\{\{${key}\}\}`, 'g')
        return _replace(agg, exp, dateTry.isValid() ? dateTry.format(dateFormat) : val)
      }, content)
    }

    return {
      getComponentContent,
      getPageContent,
      getCarrierLogoContent,
      getHelperCardContent,
      getCarrierDisclosureContent,
      getTooltipContent,
      hydratePlaceholders,
      getComponentContentCollection,
      getTooltipContentCollection,
      getFaqContentCollection,
      getSupportNumberCollection,
      getStandbyTestimonialContentCollection,
      getStandbyTestimonialContent,
      getTestimonialForm
    }
  }
}
