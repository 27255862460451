import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import undefined from '~/components/page/undefined.vue'
import type { Path } from '~/types/util'
import { ProfileStore } from '~/stores/profile'
import { RxStore } from '~/stores/rx'
import { ProviderStore } from '~/stores/provider'

export default class PushToSignUpPostNaNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    const pushToSignUp = computed(() => flag<boolean>('post-na-sign-up-push').value)

    const rx = RxStore.use()
    const providers = ProviderStore.use()

    const { isAuthenticated } = Auth.use()

    const docsOrDrugs = computed(() => rx.rxs.length > 0 || providers.providers.length > 0)

    return this.yesNo(!isAuthenticated.value && docsOrDrugs.value && pushToSignUp.value)
  }
}
