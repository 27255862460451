import { SessionStore } from '~/stores/session'
import { PlanType } from '~/generated/api-clients-generated'
import type { SupportNumber } from '~/types/cms'
import { SiteStore } from '~/stores/site'

export class SupportNumbers {
  static async use() {
    const { getSupportNumberCollection } = Cms.use()
    const { path, journeyId } = AppRoute.use()
    const session = SessionStore.use()
    const site = SiteStore.use()
    const { content } = await getSupportNumberCollection()
    const supportNumbers: SupportNumber[] = content.value

    const defaultNumber = computed(() =>
      supportNumbers.map((m) => (m.data?.planTypes.includes('DEFAULT') ? m.number : null))
    )

    const planTypePhoneNumber = computed(() =>
      supportNumbers.map((m) =>
        m.data?.paths.includes(path.value) &&
        !_isNil(session.planType) &&
        m.data?.planTypes.includes(PlanType[session.planType])
          ? m.number
          : null
      )
    )

    const excludes = computed(() => {
      return supportNumbers.map(
        (m) =>
          !!(
            m.data?.planTypes.includes('EXCLUDE') &&
            m.data?.paths.includes(path.value) &&
            m.data?.journeys.includes(String(site.journey.id))
          )
      )
    })

    const phoneNumber = computed(() =>
      excludes.value?.some((e) => e)
        ? null
        : planTypePhoneNumber.value.filter((x) => x != null).length === 0
        ? defaultNumber.value.find((x) => x != null)
        : planTypePhoneNumber.value.find((x) => x != null)
    )
    return {
      phoneNumber: phoneNumber,
      defaultNumber: defaultNumber.value.find((x) => x != null)
    }
  }
}
